import * as tslib_1 from "tslib";
import Component from "vue-class-component";
import { State } from 'vuex-class';
import Vue from 'vue';
const SlotReelProps = Vue.extend({
    props: {
        reel: {
            type: Number,
            required: true
        }
    }
});
let SlotReelComponent = class SlotReelComponent extends SlotReelProps {
    constructor() {
        super(...arguments);
        this.momentum = 0;
        this.fruits = this.$store.state.slotmachine.items.slice(0);
    }
    mounted() {
        this.$el.addEventListener("transitionend", this.animateEnd);
    }
    run() {
        this.fruits = this.slotmachine.items.slice(0); // reset the items
        let temp = this.fruits[2];
        this.fruits[2] = this.slotmachine.items[this.slotmachine.results[this.reel - 1]];
        this.fruits[this.slotmachine.results[this.reel - 1]] = temp;
        this.$forceUpdate();
        var min = 8;
        var max = 28;
        var momentum = Math.floor(Math.random() * (max - min + 1) + min);
        this.momentum = momentum;
        let audio = this.slotmachine.audio.get("spin");
        if (audio)
            audio.play();
        this.animate();
    }
    animate() {
        this.$el.classList.add("move");
    }
    animateEnd() {
        this.$el.classList.remove("move");
        this.momentum = this.momentum - 1;
        if (this.momentum > 0) {
            setTimeout(this.animate, 10);
        }
        else {
            this.$emit('stopped', this.reel);
            let audio = this.slotmachine.audio.get("spinEnd");
            if (audio) {
                audio.play();
            }
            let spinAudio = this.slotmachine.audio.get("spin");
            if (spinAudio) {
                spinAudio.pause();
                spinAudio.currentTime = 0.3;
            }
        }
    }
};
tslib_1.__decorate([
    State('slotmachine')
], SlotReelComponent.prototype, "slotmachine", void 0);
SlotReelComponent = tslib_1.__decorate([
    Component({})
], SlotReelComponent);
export default SlotReelComponent;
