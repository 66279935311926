import App from '@/App';
export default class CommandsEvent {
    parse(data) {
        App.interfaceManager.container.$store.commit('furniture/setId', data.id);
        App.interfaceManager.container.$store.commit('furniture/setSpriteId', data.spriteId);
        App.interfaceManager.container.$store.commit('furniture/setItemName', data.itemName);
        App.interfaceManager.container.$store.commit('furniture/setPublicName', data.publicName);
        App.interfaceManager.container.$store.commit('furniture/setWidth', data.width);
        App.interfaceManager.container.$store.commit('furniture/setLength', data.length);
        App.interfaceManager.container.$store.commit('furniture/setStackHeight', data.stackHeight);
        App.interfaceManager.container.$store.commit('furniture/setAllowStack', data.allowStack);
        App.interfaceManager.container.$store.commit('furniture/setAllowSit', data.allowSit);
        App.interfaceManager.container.$store.commit('furniture/setAllowLay', data.allowLay);
        App.interfaceManager.container.$store.commit('furniture/setAllowWalk', data.allowWalk);
        App.interfaceManager.container.$store.commit('furniture/setAllowGift', data.allowGift);
        App.interfaceManager.container.$store.commit('furniture/setAllowTrade', data.allowTrade);
        App.interfaceManager.container.$store.commit('furniture/setAllowRecycle', data.allowRecycle);
        App.interfaceManager.container.$store.commit('furniture/setAllowMarketplaceSell', data.allowMarketplaceSell);
        App.interfaceManager.container.$store.commit('furniture/setAllowInventoryStack', data.allowInventoryStack);
        App.interfaceManager.container.$store.commit('furniture/setType', data.type);
        App.interfaceManager.container.$store.commit('furniture/setInteractionType', data.interactionType);
        App.interfaceManager.container.$store.commit('furniture/setInteractionModesCount', data.interactionModesCount);
        App.interfaceManager.container.$store.commit('furniture/setVendingIds', data.vendingIds);
        App.interfaceManager.container.$store.commit('furniture/setMultiheight', data.multiheight);
        App.interfaceManager.container.$store.commit('furniture/setCustomParams', data.customParams);
        App.interfaceManager.container.$store.commit('furniture/setEffectIdMale', data.effectIdMale);
        App.interfaceManager.container.$store.commit('furniture/setEffectIdFemale', data.effectIdFemale);
        App.interfaceManager.container.$store.commit('furniture/setClothingOnWalk', data.clothingOnWalk);
        App.interfaceManager.container.$store.commit('furniture/setOpen', true);
        App.interfaceManager.bus.$emit("updateFurnifix");
    }
}
