import App from '@/App';
import LoadedComposer from "@/communication/outgoing/loaded/LoadedComposer";
export default class SessionDataEvent {
    parse(data) {
        let sendLoaded = true;
        const state = App.interfaceManager.container.$store.state.session;
        if (state.user.id != 0) {
            sendLoaded = false;
        }
        App.interfaceManager.container.$store.commit('session/setCredits', data.credits);
        App.interfaceManager.container.$store.commit('session/setId', data.id);
        App.interfaceManager.container.$store.commit('session/setUsername', data.username);
        App.interfaceManager.container.$store.commit('session/setLook', data.look);
        if (sendLoaded) {
            App.communicationManager.sendMessage(new LoadedComposer());
        }
    }
}
