import Logger from '@/utils/Logger';
import App from './App';
import { CommunicationType } from './communication/CommunicationType';
App.init();
App.interfaceManager.initInterface();
window.startYTOverlay = function (sso, wsUrl) {
    if (sso && wsUrl) {
        App.communicationManager.mode = CommunicationType.WebSocket;
        App.communicationManager.wsUrl = wsUrl;
        App.communicationManager.sso = sso;
    }
    else {
        App.communicationManager.mode = CommunicationType.ExternalFlashInterface;
    }
    initExternalFlashInterface();
    Logger.Log(`Started application with mode ${App.communicationManager.mode}`);
};
window.connectWebSocket = function () {
    App.communicationManager.connectWebSocket();
};
let initExternalFlashInterface = function () {
    if (!window.FlashExternalInterface) {
        window.FlashExternalInterface = {
            legacyTrack: (arg1, arg2, arg3) => { },
            listPlugins: () => { return ''; },
            logEventLog: (arg1) => { },
            track: (arg1, arg2, arg3) => { },
            logDebug: (arg1) => { },
            logWarn: (arg1) => { },
            logCrash: (arg1) => { },
            logError: (arg1) => { },
            openPage: (arg1) => { },
            openExternalLink: (arg1) => { },
            openHabblet: (arg1, arg2) => { },
            openWebPageAndMinimizeClient: (arg1) => { },
        };
    }
    window.FlashExternalInterface.openHabblet = function (arg1, arg2) {
        App.communicationManager.onMessage(arg1);
    };
    window.FlashExternalInterface.legacyTrack = function (arg1, arg2, arg3) {
        if (arg1 === "authentication") {
            if (App.communicationManager.mode === CommunicationType.WebSocket) {
                App.communicationManager.connectWebSocket();
            }
            else {
                App.communicationManager.onOpen();
            }
        }
    };
    window.FlashExternalInterface.listPlugins = function () {
        let txt = "";
        for (var i = 0; i < navigator.plugins.length; i++) {
            txt += navigator.plugins[i].name + "|";
        }
        return txt;
    };
};
initExternalFlashInterface();
let frame = document.getElementById('nitro');
//@ts-ignore
if (NitroConfig || frame) {
    App.communicationManager.mode = CommunicationType.IFrameExternalFlashInterface;
    App.communicationManager.onOpen(); //force connection to true for now
}
if (frame && frame.contentWindow) {
    window.addEventListener('message', (ev) => {
        if (!frame || ev.source !== frame.contentWindow)
            return;
        const legacyInterface = 'Nitro_LegacyExternalInterface';
        if (typeof ev.data !== 'string')
            return;
        if (ev.data.startsWith(legacyInterface)) {
            const { method, params } = JSON.parse(ev.data.substr(legacyInterface.length));
            if (!('FlashExternalInterface' in window))
                return;
            const fn = window.FlashExternalInterface[method];
            if (!fn)
                return;
            fn(...params);
            return;
        }
    });
}
