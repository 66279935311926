import store from '@/store/Store';
import Vue from 'vue';
import App from './App.vue';
export default class InterfaceManager {
    constructor() {
        this._bus = new Vue();
        Vue.config.productionTip = false;
        Vue.config.devtools = true;
    }
    initInterface() {
        this._container = new Vue({
            store,
            render: h => h(App)
        }).$mount("#app");
        this.container.$store.commit('slotmachine/initAudio');
    }
    get container() {
        return this._container;
    }
    get bus() {
        return this._bus;
    }
}
