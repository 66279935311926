import * as tslib_1 from "tslib";
import Component from 'vue-class-component';
import { State } from 'vuex-class';
import Vue from 'vue';
let YoutubeVideoComponent = class YoutubeVideoComponent extends Vue {
    getVideoUrl() {
        return "https://www.youtube.com/embed/" + this.youtubeplayer.videoId + "?autoplay=1";
    }
};
tslib_1.__decorate([
    State('youtubeplayer')
], YoutubeVideoComponent.prototype, "youtubeplayer", void 0);
YoutubeVideoComponent = tslib_1.__decorate([
    Component
], YoutubeVideoComponent);
export default YoutubeVideoComponent;
