import * as tslib_1 from "tslib";
import Component from "vue-class-component";
import { State } from 'vuex-class';
import Vue from 'vue';
import './dragger/dragger';
let CommandsComponent = class CommandsComponent extends Vue {
    constructor() {
        super(...arguments);
        this.search = "";
    }
    data() {
        return {};
    }
    mounted() {
    }
    filteredList() {
        return (this.commands.values).filter(command => {
            return command.toLowerCase().includes(this.search.toLowerCase());
        });
    }
    close() {
        this.$store.commit('commands/setOpen', false);
    }
};
tslib_1.__decorate([
    State(state => state.commands)
], CommandsComponent.prototype, "commands", void 0);
CommandsComponent = tslib_1.__decorate([
    Component
], CommandsComponent);
export default CommandsComponent;
