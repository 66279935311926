import * as tslib_1 from "tslib";
import Component from "vue-class-component";
import { State } from 'vuex-class';
import Vue from 'vue';
let TwitchVideoComponent = class TwitchVideoComponent extends Vue {
    getVideoUrl() {
        return "https://player.twitch.tv/?channel=" + this.twitchplayer.channel + "&parent=" + window.location.hostname + "&autoplay=true&muted=false";
    }
};
tslib_1.__decorate([
    State('twitchplayer')
], TwitchVideoComponent.prototype, "twitchplayer", void 0);
TwitchVideoComponent = tslib_1.__decorate([
    Component
], TwitchVideoComponent);
export default TwitchVideoComponent;
