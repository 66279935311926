import * as tslib_1 from "tslib";
import Component from "vue-class-component";
import { State } from 'vuex-class';
import App from '@/App';
import Vue from 'vue';
import EditFurniComposer from "@/communication/outgoing/furnifix/EditFurniComposer";
import '../dragger/dragger';
let EditFurnitureComponent = class EditFurnitureComponent extends Vue {
    data() {
        return {
            id: 0,
            spriteId: 0,
            itemName: "",
            publicName: "",
            width: 0,
            length: 0,
            stackHeight: 0.0,
            allowStack: '',
            allowSit: '',
            allowLay: '',
            allowWalk: '',
            allowGift: '',
            allowTrade: '',
            allowRecycle: '',
            allowMarketplaceSell: '',
            allowInventoryStack: '',
            type: '',
            interactionType: '',
            interactionModesCount: 0,
            vendingIds: '',
            multiheight: '',
            customParams: '',
            effectIdMale: 0,
            effectIdFemale: 0,
            clothingOnWalk: ''
        };
    }
    mounted() {
        this.update();
        App.interfaceManager.bus.$on("updateFurnifix", () => {
            this.update();
        });
    }
    update() {
        this.$data.id = this.furniture.id;
        this.$data.spriteId = this.furniture.spriteId;
        this.$data.itemName = this.furniture.itemName;
        this.$data.publicName = this.furniture.publicName;
        this.$data.width = this.furniture.width;
        this.$data.length = this.furniture.length;
        this.$data.stackHeight = this.furniture.stackHeight;
        this.$data.allowStack = this.furniture.allowStack;
        this.$data.allowSit = this.furniture.allowSit;
        this.$data.allowLay = this.furniture.allowLay;
        this.$data.allowWalk = this.furniture.allowWalk;
        this.$data.allowGift = this.furniture.allowGift;
        this.$data.allowTrade = this.furniture.allowTrade;
        this.$data.allowRecycle = this.furniture.allowRecycle;
        this.$data.allowMarketplaceSell = this.furniture.allowMarketplaceSell;
        this.$data.allowInventoryStack = this.furniture.allowInventoryStack;
        this.$data.type = this.furniture.type;
        this.$data.interactionType = this.furniture.interactionType;
        this.$data.interactionModesCount = this.furniture.interactionModesCount;
        this.$data.vendingIds = this.furniture.vendingIds;
        this.$data.multiheight = this.furniture.multiheight;
        this.$data.customParams = this.furniture.customParams;
        this.$data.effectIdMale = this.furniture.effectIdMale;
        this.$data.effectIdFemale = this.furniture.effectIdFemale;
        this.$data.clothingOnWalk = this.furniture.clothingOnWalk;
    }
    close() {
        this.$store.commit('furniture/setOpen', false);
    }
    save() {
        App.communicationManager.sendMessage(new EditFurniComposer(this.$data.id, this.$data.spriteId, this.$data.itemName, this.$data.publicName, this.$data.width, this.$data.length, this.$data.stackHeight, this.$data.allowStack, this.$data.allowSit, this.$data.allowLay, this.$data.allowWalk, this.$data.allowGift, this.$data.allowTrade, this.$data.allowRecycle, this.$data.allowMarketplaceSell, this.$data.allowInventoryStack, this.$data.type, this.$data.interactionType, this.$data.interactionModesCount, this.$data.vendingIds, this.$data.multiheight, this.$data.customParams, this.$data.effectIdMale, this.$data.effectIdFemale, this.$data.clothingOnWalk));
    }
};
tslib_1.__decorate([
    State("furniture")
], EditFurnitureComponent.prototype, "furniture", void 0);
EditFurnitureComponent = tslib_1.__decorate([
    Component
], EditFurnitureComponent);
export default EditFurnitureComponent;
