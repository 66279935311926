export default class AddSongComposer {
    constructor(song) {
        this.header = "add_song";
        this.data = {
            "name": song.name,
            "videoId": song.videoId,
            "channel": song.channel
        };
    }
}
