import MovementInteraction from './MovementInteraction';
export default class InteractionManager {
    constructor() {
        this._interactions = new Map();
        this.registerInteractions();
    }
    registerInteractions() {
        this._interactions.set("movement", new MovementInteraction());
    }
    get interactions() {
        return this._interactions;
    }
}
