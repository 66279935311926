const state = {
    open: false,
    playing: false,
    playlist: [],
    currentIndex: 0
};
const namespaced = true;
const mutations = {
    setOpen(state, open) {
        state.open = open;
    },
    setPlaying(state, playing) {
        state.playing = playing;
    },
    setPlaylist(state, playlist) {
        state.playlist = playlist;
    },
    addToPlaylist(state, song) {
        state.playlist.push(song);
    },
    removeFromPlaylist(state, index) {
        state.playlist.splice(index, 1);
    },
    setCurrentIndex(state, index) {
        state.currentIndex = index;
    },
    incrementIndex(state) {
        state.currentIndex++;
    },
    decrementIndex(state) {
        state.currentIndex--;
    }
};
export const jukebox = {
    namespaced,
    state,
    mutations
};
