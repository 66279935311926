export var KeyboardKeys;
(function (KeyboardKeys) {
    KeyboardKeys[KeyboardKeys["Backspace"] = 8] = "Backspace";
    KeyboardKeys[KeyboardKeys["Tab"] = 9] = "Tab";
    KeyboardKeys[KeyboardKeys["Clear"] = 12] = "Clear";
    KeyboardKeys[KeyboardKeys["Enter"] = 13] = "Enter";
    KeyboardKeys[KeyboardKeys["Shift"] = 16] = "Shift";
    KeyboardKeys[KeyboardKeys["Control"] = 17] = "Control";
    KeyboardKeys[KeyboardKeys["Alt"] = 18] = "Alt";
    KeyboardKeys[KeyboardKeys["Esc"] = 29] = "Esc";
    KeyboardKeys[KeyboardKeys["Spacebar"] = 32] = "Spacebar";
    KeyboardKeys[KeyboardKeys["Left"] = 37] = "Left";
    KeyboardKeys[KeyboardKeys["Up"] = 38] = "Up";
    KeyboardKeys[KeyboardKeys["Right"] = 39] = "Right";
    KeyboardKeys[KeyboardKeys["Down"] = 40] = "Down";
})(KeyboardKeys || (KeyboardKeys = {}));
