import Vue from 'vue';
Vue.directive('dragger', {
    bind(el, binding) {
        Vue.nextTick(() => {
            const name = binding.expression.slice(6);
            const headerElement = document.getElementById(name); // Use the provided header element or the element itself as the header
            console.log(name + " - " + headerElement);
            headerElement.onmousedown = (e) => {
                e.preventDefault();
                let pos3 = e.clientX;
                let pos4 = e.clientY;
                document.onmouseup = () => {
                    console.log("it gets here 2");
                    document.onmouseup = null;
                    document.onmousemove = null;
                };
                document.onmousemove = (e) => {
                    console.log("it gets here 3");
                    e.preventDefault();
                    const posX = pos3 - e.clientX;
                    const posY = pos4 - e.clientY;
                    pos3 = e.clientX;
                    pos4 = e.clientY;
                    el.style.top = (el.offsetTop - posY) + 'px';
                    el.style.left = (el.offsetLeft - posX) + 'px';
                };
            };
        });
    },
});
