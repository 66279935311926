import * as tslib_1 from "tslib";
import Component from 'vue-class-component';
import TwitchVideoComponent from './TwitchVideoComponent.vue';
import { State } from 'vuex-class';
import Vue from 'vue';
import '../dragger/dragger';
let TwitchComponent = class TwitchComponent extends Vue {
    data() {
        return {};
    }
    mounted() {
    }
    close() {
        this.$store.commit('twitchplayer/setOpen', false);
    }
    centerBox() {
        let Width = 560;
        let cWi = ((window.innerWidth || (document != null && document.documentElement != null && document.documentElement.clientWidth) || document.body.clientWidth) / 2) - (Width / 2);
        let cHe = Math.floor((((window.innerHeight || (document != null && document.documentElement != null && document.documentElement.clientHeight) || document.body.clientHeight) / 2) / 100) * 60);
        return { left: cWi + "px", top: cHe + "px" };
    }
};
tslib_1.__decorate([
    State('twitchplayer')
], TwitchComponent.prototype, "twitchplayer", void 0);
TwitchComponent = tslib_1.__decorate([
    Component({
        components: {
            TwitchVideoComponent
        }
    })
], TwitchComponent);
export default TwitchComponent;
