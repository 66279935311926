import Vue from 'vue';
import Vuex from 'vuex';
import { session } from './modules/session';
import { commands } from './modules/commands';
import { jukebox } from './modules/jukebox';
import { slotmachine } from './modules/slotmachine';
import { youtubeplayer } from './modules/youtubeplayer';
import { twitchplayer } from './modules/twitchplayer';
import { furniture } from './modules/furniture';
Vue.use(Vuex);
const store = {
    state: {
        connected: false
    },
    modules: {
        session,
        commands,
        jukebox,
        slotmachine,
        youtubeplayer,
        twitchplayer,
        furniture
    },
    mutations: {
        setConnected(state, val) {
            state.connected = val;
        }
    }
};
export default new Vuex.Store(store);
