import * as tslib_1 from "tslib";
import Component from "vue-class-component";
import { State } from 'vuex-class';
import Logger from "@/utils/Logger";
import Constants from "@/utils/Constants";
import axios from "axios";
import Song from "@/store/models/Song";
import AddSongComposer from '@/communication/outgoing/jukebox/AddSongComposer';
import RemoveSongComposer from '@/communication/outgoing/jukebox/RemoveSongComposer';
import PreviousSongComposer from '@/communication/outgoing/jukebox/PreviousSongComposer';
import NextSongComposer from '@/communication/outgoing/jukebox/NextSongComposer';
import PlayStopComposer from '@/communication/outgoing/jukebox/PlayStopComposer';
import RegexUtility from '@/utils/RegexUtility';
import App from '@/App';
import Vue from 'vue';
import '../dragger/dragger';
let JukeboxComponent = class JukeboxComponent extends Vue {
    constructor() {
        super(...arguments);
        this.searchKeyword = "";
        this.searchResults = [];
        this.mode = 1;
        this.videoid = "";
        this.name = "";
    }
    data() {
        return {};
    }
    mounted() {
    }
    search() {
        if (this.searchKeyword == "")
            return;
        axios
            .get(Constants.getYoutubeApiUrl() + this.searchKeyword)
            .then((response) => {
            this.searchResults = response.data.items.filter((res) => {
                return res.id.kind.includes("youtube#video");
            });
        })
            .catch((error) => {
            Logger.Log(error);
        });
    }
    submitUrl() {
        if (this.videoid == "" || this.name == "") {
            alert("Fill out all form fields");
            return;
        }
        let id = RegexUtility.getVideoIdFromYoutubeURL(this.videoid);
        if (id != "") {
            this.videoid = id;
            App.communicationManager.sendMessage(new AddSongComposer(new Song(this.name, this.videoid, "unknown")));
        }
        else {
            alert("Invalid youtube url");
        }
    }
    playStop() {
        App.communicationManager.sendMessage(new PlayStopComposer(!this.jukebox.playing));
    }
    playNext() {
        App.communicationManager.sendMessage(new NextSongComposer(this.jukebox.currentIndex));
    }
    playPrev() {
        App.communicationManager.sendMessage(new PreviousSongComposer(this.jukebox.currentIndex));
    }
    removeSong(index) {
        App.communicationManager.sendMessage(new RemoveSongComposer(index));
    }
    addSong(index) {
        if (this.searchResults[index].id.videoId != undefined) {
            App.communicationManager.sendMessage(new AddSongComposer(new Song(this.searchResults[index].snippet.title, this.searchResults[index].id.videoId, this.searchResults[index].snippet.channelTitle)));
        }
    }
    close() {
        this.$store.commit('jukebox/setOpen', false);
    }
};
tslib_1.__decorate([
    State('jukebox')
], JukeboxComponent.prototype, "jukebox", void 0);
JukeboxComponent = tslib_1.__decorate([
    Component
], JukeboxComponent);
export default JukeboxComponent;
