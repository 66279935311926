export default class EditFurniComposer {
    constructor(id, spriteId, itemName, publicName, width, length, stackHeight, allowStack, allowSit, allowLay, allowWalk, allowGift, allowTrade, allowRecycle, allowMarketplaceSell, allowInventoryStack, type, interactionType, interactionModesCount, vendingIds, multiheight, customParams, effectIdMale, effectIdFemale, clothingOnWalk) {
        this.header = "edit_furni";
        this.data = {
            "id": id,
            "spriteId": spriteId,
            "itemName": itemName,
            "publicName": publicName,
            "width": width,
            "length": length,
            "stackHeight": stackHeight,
            "allowStack": allowStack,
            "allowSit": allowSit,
            "allowLay": allowLay,
            "allowWalk": allowWalk,
            "allowGift": allowGift,
            "allowTrade": allowTrade,
            "allowRecycle": allowRecycle,
            "allowMarketplaceSell": allowMarketplaceSell,
            "allowInventoryStack": allowInventoryStack,
            "type": type,
            "interactionType": interactionType,
            "interactionModesCount": interactionModesCount,
            "vendingIds": vendingIds,
            "multiheight": multiheight,
            "customParams": customParams,
            "effectIdMale": effectIdMale,
            "effectIdFemale": effectIdFemale,
            "clothingOnWalk": clothingOnWalk
        };
    }
}
