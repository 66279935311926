const state = {
    values: [],
    open: false
};
const namespaced = true;
const mutations = {
    setCommands(state, commands) {
        state.values = commands;
    },
    setOpen(state, open) {
        state.open = open;
    }
};
export const commands = {
    namespaced,
    state,
    mutations
};
