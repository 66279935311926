export default class SongEndedComposer {
    constructor() {
        this.header = "song_ended";
    }
    composer(currentIndex) {
        this.data = {
            "currentIndex": currentIndex
        };
    }
}
