import * as tslib_1 from "tslib";
import Component from 'vue-class-component';
import YoutubeVideoComponent from './YoutubeVideoComponent.vue';
import YoutubeEditComponent from './YoutubeEditComponent.vue';
import { State } from 'vuex-class';
import Vue from 'vue';
import '../dragger/dragger';
let YoutubeComponent = class YoutubeComponent extends Vue {
    data() {
        return {};
    }
    mounted() {
    }
    close() {
        this.$store.commit('youtubeplayer/setOpen', false);
    }
    edit() {
        this.$store.commit('youtubeplayer/setEditMode', true);
    }
    centerBox() {
        let Width = 560;
        let cWi = ((window.innerWidth || (document != null && document.documentElement != null && document.documentElement.clientWidth) || document.body.clientWidth) / 2) - (Width / 2);
        let cHe = Math.floor((((window.innerHeight || (document != null && document.documentElement != null && document.documentElement.clientHeight) || document.body.clientHeight) / 2) / 100) * 60);
        return { left: cWi + "px", top: cHe + "px" };
    }
};
tslib_1.__decorate([
    State('youtubeplayer')
], YoutubeComponent.prototype, "youtubeplayer", void 0);
YoutubeComponent = tslib_1.__decorate([
    Component({
        components: {
            YoutubeVideoComponent,
            YoutubeEditComponent
        }
    })
], YoutubeComponent);
export default YoutubeComponent;
