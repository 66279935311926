const state = {
    open: false,
    editMode: false,
    videoId: "",
    itemId: 0
};
const namespaced = true;
const mutations = {
    setOpen(state, open) {
        state.open = open;
    },
    setEditMode(state, editMode) {
        state.editMode = editMode;
    },
    setVideoId(state, videoId) {
        state.videoId = videoId;
    },
    setItemId(state, itemId) {
        state.itemId = itemId;
    }
};
export const youtubeplayer = {
    namespaced,
    state,
    mutations
};
