import * as tslib_1 from "tslib";
import Component from "vue-class-component";
import SlotReelComponent from "./SlotReelComponent.vue";
import RequestSpinSlotMachineComposer from "@/communication/outgoing/general/RequestSpinSlotMachineComposer";
import RequestCreditsComposer from "@/communication/outgoing/general/RequestCreditsComposer";
import App from '@/App';
import { State } from 'vuex-class';
import Vue from 'vue';
import '../dragger/dragger';
let SlotMachineComponent = class SlotMachineComponent extends Vue {
    constructor() {
        super(...arguments);
        this.bet = 1;
        this.won = 0;
    }
    data() {
        return {};
    }
    mounted() {
        App.interfaceManager.bus.$on("results", () => {
            this.onResults();
        });
    }
    onResults() {
        this.$refs.reel1.run();
    }
    reelStopped(reel) {
        console.log(reel);
        if (reel == 1) {
            this.$refs.reel2.run();
        }
        else if (reel == 2) {
            this.$refs.reel3.run();
        }
        else {
            this.checkWin();
        }
    }
    spin() {
        if (this.bet == NaN) {
            return;
        }
        if (this.session.credits > 0 &&
            this.slotmachine.results.length == 0 &&
            !this.slotmachine.isSpinning) {
            this.won = 0;
            let itemId = this.slotmachine.itemId;
            App.communicationManager.sendMessage(new RequestSpinSlotMachineComposer(itemId, this.bet));
        }
    }
    checkWin() {
        if (this.slotmachine.results.length === 3) {
            if (this.slotmachine.won) {
                let payout = this.slotmachine.payout;
                if (payout > 100) {
                    let audio = this.slotmachine.audio.get("bigwin");
                    if (audio)
                        audio.play();
                }
                else {
                    let audio = this.slotmachine.audio.get("win");
                    if (audio)
                        audio.play();
                }
                App.communicationManager.sendMessage(new RequestCreditsComposer());
                this.won = this.slotmachine.payout;
            }
            else {
                console.log("lost");
            }
        }
        this.$store.commit('slotmachine/setResults', []);
        this.$store.commit('slotmachine/setSpinning', false);
        this.$store.commit('slotmachine/setWon', false);
    }
    close() {
        this.$store.commit('slotmachine/setOpen', false);
    }
};
tslib_1.__decorate([
    State('slotmachine')
], SlotMachineComponent.prototype, "slotmachine", void 0);
tslib_1.__decorate([
    State('session')
], SlotMachineComponent.prototype, "session", void 0);
SlotMachineComponent = tslib_1.__decorate([
    Component({
        components: {
            SlotReelComponent
        }
    })
], SlotMachineComponent);
export default SlotMachineComponent;
