import * as tslib_1 from "tslib";
import Component from 'vue-class-component';
import axios from 'axios';
import EditTVComposer from '@/communication/outgoing/general/EditTVComposer';
import Logger from '@/utils/Logger';
import Constants from '@/utils/Constants';
import RegexUtility from '../../../utils/RegexUtility';
import App from '@/App';
import { State } from 'vuex-class';
import Vue from 'vue';
let YoutubeEditComponent = class YoutubeEditComponent extends Vue {
    constructor() {
        super(...arguments);
        this.videoid = "";
        this.mode = 1;
        this.searchKeyword = "";
        this.searchResults = [];
    }
    sendEdit() {
        if (this.videoid == "")
            return;
        App.communicationManager.sendMessage(new EditTVComposer(this.youtubeplayer.itemId, this.videoid));
        this.$store.commit('youtubeplayer/setOpen', false);
        this.videoid = "";
        this.searchKeyword = "";
        this.searchResults = null;
    }
    clickOnItem(index) {
        if (this.searchResults[index].id.videoId != undefined)
            this.videoid = this.searchResults[index].id.videoId;
        this.sendEdit();
    }
    submitUrl() {
        let id = RegexUtility.getVideoIdFromYoutubeURL(this.videoid);
        if (id != "") {
            this.videoid = id;
            this.sendEdit();
        }
        else {
            alert("Invalid youtube url");
        }
    }
    search() {
        if (this.searchKeyword == "")
            return;
        axios.get(Constants.getYoutubeApiUrl() + this.searchKeyword).then((response) => {
            this.searchResults = response.data.items;
        }).catch((error) => {
            Logger.Log(error);
        });
    }
};
tslib_1.__decorate([
    State('youtubeplayer')
], YoutubeEditComponent.prototype, "youtubeplayer", void 0);
YoutubeEditComponent = tslib_1.__decorate([
    Component
], YoutubeEditComponent);
export default YoutubeEditComponent;
