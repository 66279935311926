import App from '@/App';
export default class TwitchVideoEvent {
    parse(data) {
        let channel = data.videoId;
        if (channel == "")
            return;
        App.interfaceManager.container.$store.commit('twitchplayer/setChannel', channel);
        App.interfaceManager.container.$store.commit('twitchplayer/setOpen', true);
    }
}
