const state = {
    id: 0,
    spriteId: 0,
    itemName: "",
    publicName: "",
    width: 0,
    length: 0,
    stackHeight: 0.0,
    allowStack: '',
    allowSit: '',
    allowLay: '',
    allowWalk: '',
    allowGift: '',
    allowTrade: '',
    allowRecycle: '',
    allowMarketplaceSell: '',
    allowInventoryStack: '',
    type: '',
    interactionType: '',
    interactionModesCount: 0,
    vendingIds: '',
    multiheight: '',
    customParams: '',
    effectIdMale: 0,
    effectIdFemale: 0,
    clothingOnWalk: '',
    open: false
};
const namespaced = true;
const mutations = {
    setId(state, id) {
        state.id = id;
    },
    setSpriteId(state, spriteId) {
        state.spriteId = spriteId;
    },
    setItemName(state, itemName) {
        state.itemName = itemName;
    },
    setPublicName(state, publicName) {
        state.publicName = publicName;
    },
    setWidth(state, width) {
        state.width = width;
    },
    setLength(state, length) {
        state.length = length;
    },
    setStackHeight(state, stackHeight) {
        state.stackHeight = stackHeight;
    },
    setAllowStack(state, allowStack) {
        state.allowStack = allowStack;
    },
    setAllowSit(state, allowSit) {
        state.allowSit = allowSit;
    },
    setAllowLay(state, allowLay) {
        state.allowLay = allowLay;
    },
    setAllowWalk(state, allowWalk) {
        state.allowWalk = allowWalk;
    },
    setAllowGift(state, allowGift) {
        state.allowGift = allowGift;
    },
    setAllowTrade(state, allowTrade) {
        state.allowTrade = allowTrade;
    },
    setAllowRecycle(state, allowRecycle) {
        state.allowRecycle = allowRecycle;
    },
    setAllowMarketplaceSell(state, allowMarketplaceSell) {
        state.allowMarketplaceSell = allowMarketplaceSell;
    },
    setAllowInventoryStack(state, allowInventoryStack) {
        state.allowInventoryStack = allowInventoryStack;
    },
    setType(state, type) {
        state.type = type;
    },
    setInteractionType(state, interactionType) {
        state.interactionType = interactionType;
    },
    setInteractionModesCount(state, interactionModesCount) {
        state.interactionModesCount = interactionModesCount;
    },
    setVendingIds(state, vendingIds) {
        state.vendingIds = vendingIds;
    },
    setMultiheight(state, multiheight) {
        state.multiheight = multiheight;
    },
    setCustomParams(state, customParams) {
        state.customParams = customParams;
    },
    setEffectIdMale(state, effectIdMale) {
        state.effectIdMale = effectIdMale;
    },
    setEffectIdFemale(state, effectIdFemale) {
        state.effectIdFemale = effectIdFemale;
    },
    setClothingOnWalk(state, clothingOnWalk) {
        state.clothingOnWalk = clothingOnWalk;
    },
    setOpen(state, open) {
        state.open = open;
    }
};
export const furniture = {
    namespaced,
    state,
    mutations
};
