import * as tslib_1 from "tslib";
import Component from "vue-class-component";
import YoutubeComponent from "./components/youtube/YoutubeComponent.vue";
import SlotMachineComponent from "./components/slot-machine/SlotMachineComponent.vue";
import CommandsComponent from "./components/CommandsComponent.vue";
import JukeboxComponent from "./components/jukebox/JukeboxComponent.vue";
import JukeboxYoutubeComponent from "./components/jukebox/JukeboxYoutubeComponent.vue";
import TwitchComponent from "./components/twitch/TwitchComponent.vue";
import EditFurnitureComponent from "./components/furnifix/EditFurnitureComponent.vue";
import Vue from "vue";
import { State } from 'vuex-class';
let App = class App extends Vue {
};
tslib_1.__decorate([
    State('youtubeplayer')
], App.prototype, "youtubeplayer", void 0);
tslib_1.__decorate([
    State('commands')
], App.prototype, "commands", void 0);
tslib_1.__decorate([
    State('twitchplayer')
], App.prototype, "twitchplayer", void 0);
tslib_1.__decorate([
    State('slotmachine')
], App.prototype, "slotmachine", void 0);
tslib_1.__decorate([
    State('jukebox')
], App.prototype, "jukebox", void 0);
tslib_1.__decorate([
    State('furniture')
], App.prototype, "furniture", void 0);
App = tslib_1.__decorate([
    Component({
        components: {
            YoutubeComponent,
            SlotMachineComponent,
            CommandsComponent,
            JukeboxComponent,
            JukeboxYoutubeComponent,
            TwitchComponent,
            EditFurnitureComponent
        },
    })
], App);
export default App;
